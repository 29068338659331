
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Media,
  CardImg,
  Badge,
} from "reactstrap";
// core components
import { IndexNavbar } from "components/Navbars/IndexNavbar.jsx";
// import IndexHeader from "components/Headers/IndexHeader.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
// import AuthNavbar from "components/Navbars/AuthNavbar.jsx";

import {FeaturedCelebsSection, FeaturedEventsSection,} from "components";
import { PulseLoader } from 'react-spinners';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import ResponsiveEmbed  from "react-responsive-embed";
import AppleSignIn from "components/AppleSignIn/AppleSignIn.jsx";
import { history, isValidEmail, isProductionBuild } from '_helpers';
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
import { config } from '_constants';
import { socialMediaActions, authActions, userActions, screenSizeActions, registerActions} from '_actions';
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import NotificationAlert from "components/react-notification-alert/NotificationAlert";
import defaultAvatar from "assets/img/default-avatar.png"
// import liveNyteIcon from "assets/img/img-livenyte/livenyte-square-icon.jpg"
// import {Helmet} from "react-helmet";
import AutoPlaySilentVideo from "components/AutoPlaySilentVideo/AutoPlaySilentVideo.js"
// import Linkify from 'react-linkify';
// import rocket from "assets/img/img-livenyte/rocketLiveNyte_cmrps.jpg"
// import lightbulb from "assets/img/img-livenyte/lightbulb_cmprs.jpg";
// import livenyteLogo from "assets/img/img-livenyte/livenyte-square-icon.jpg"

import musicianVideoFallback from "assets/img/img-livenyte/fallback_musician_preview_cmprs.jpg";
import crowdshot from "assets/img/img-livenyte/crowdshot.jpg";
import rainbowRoom from "assets/img/img-livenyte/rainbowRoom.jpg";
import silouhette from "assets/img/img-livenyte/silouhette.jpg";
import landingPlaceholder from "assets/img/img-livenyte/landingVideoScreen.jpg";
import CookieManager from "components/CookieManager/CookieManager.jsx"
import eventOrganizer from "assets/img/img-livenyte/eventOrganizer.jpg";

const queryString = require('query-string');

class IndexView extends React.Component {
  
  constructor(props) {
    super(props);
    let openSignUpModal = false 
      if (props.location.state && props.location.state.hasOwnProperty('openSignUpModal')) {
        openSignUpModal = props.location.state.openSignUpModal
      }
      const screenSize = function (is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest) {
        console.log("testB", is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest, window.innerWidth, updateScreenSizeRequest )
        console.log()
        if (window.innerWidth < 430 && !updateScreenSizeRequest && !(is_xxs && is_xs && is_sm && is_md && is_lg && is_xl && is_xxl) ) { //phones /Does it initialize as XXS? 
          props.dispatch(screenSizeActions.setScreenSizeXXS())
          return
        }
        else if (window.innerWidth >= 430 && window.innerWidth < 577 && !updateScreenSizeRequest && !(!is_xxs && is_xs && is_sm && is_md && is_lg && is_xl && is_xxl)) { //Does it initialize as XS? 
          props.dispatch(screenSizeActions.setScreenSizeXS())
          return
        } else if (window.innerWidth >= 577 && window.innerWidth < 769 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && is_sm && is_md && is_lg && is_xl && is_xxl)) { //Does it initialize as SM? 
          props.dispatch(screenSizeActions.setScreenSizeSM())
          return
        } else if (window.innerWidth >= 769 && window.innerWidth < 993 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && is_md && is_lg && is_xl && is_xxl)) { //Does it initialize as MD? 
          props.dispatch(screenSizeActions.setScreenSizeMD())
          return
        } else if (window.innerWidth >= 993 && window.innerWidth < 1201 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && !is_md && is_lg && is_xl && is_xxl)) {
          props.dispatch(screenSizeActions.setScreenSizeLG())
          return
        } else if (window.innerWidth >= 1201 && window.innerWidth < 1400 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && !is_md && !is_lg && is_xl && is_xxl)) {
          props.dispatch(screenSizeActions.setScreenSizeXL())
          return
        } else if (window.innerWidth >= 1400 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && !is_md && !is_lg && !is_xl && is_xxl)) {
          props.dispatch(screenSizeActions.setScreenSizeXXL())
          return
        }
      }
    
    this.state = {
      is_xs: false,
      is_sm: false,
      is_lg: false,
      username: '',
      password: '',
      updateTerms: false,
      termsPrivacyModal: false,
      user: null,
      hTabs: "ht1",
      alert: null,
      notificationMessage: [], // [ { message: '', color: ''}]
      signUpModal: false,
      email: '',
      openSignUpModal: openSignUpModal, 
      screenSize: screenSize,
      isVideoLoaded: false,

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleTermsPrivacyModal = this.toggleTermsPrivacyModal.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.notify = this.notify.bind(this);
    this.toggleSignUpModal = this.toggleSignUpModal.bind(this)
    this.setActiveContainer = this.setActiveContainer.bind(this);
    this.openSignIn = this.openSignIn.bind(this)
    this.openSignUp = this.openSignUp.bind(this)
    this.responseGoogle = this.responseGoogle.bind(this);
    this.handleVideo = this.handleVideo.bind(this)
    this.handleScreenSize = this.handleScreenSize.bind(this)
    this.reactivateAccountAlert = this.reactivateAccountAlert.bind(this)
    this.onLoadedData = this.onLoadedData.bind(this)
    this.startSocialRegistration = this.startSocialRegistration.bind(this);
    this.showUnauthorizedSocialMediaAlert = this.showUnauthorizedSocialMediaAlert.bind(this);
  }

  componentDidMount() {
    const { openSignUpModal } = this.state
    window.scrollTo(0, 0);
    this.setPropsScreenSize()
    window.addEventListener('resize', this.handleScreenSize);  

    if (this.props.location.search && this.props.location.search !== "") {
      let query = queryString.parse(this.props.location.search);
      console.log("query", query)
      if (Object.prototype.hasOwnProperty.call(query, 'code')) {
        console.log("query", query)
        console.log("code", query.code)
        
        let rs = localStorage.getItem('fb-nonce');
        if (query.state === rs) {
          console.log("state match!!")
          // this.props.dispatch(userActions.facebookGetAccessToken(query.code, {action: 'change-username'}))
          localStorage.removeItem('fb-nonce');
        } else {
          console.log("state does not match", query.state, rs)
        }
      } 
    }
    if (openSignUpModal === "register") {
      this.openSignUp()
    } else  if (openSignUpModal === "login") {
      this.openSignIn()
    }
    this.setState({
      openSignUpModal: false 
    })
    this.setupVideo() 
  }

  onLoadedData = () => {
    this.setState({
      isVideoLoaded: true 
    })
  };

  componentWillUnmount(){
    document.body.removeEventListener('mousedown', () => this.handleVideo('mousedown'));
    window.removeEventListener('resize', this.handleScreenSize);  

  }

  handleScreenSize() {
    const { screenSize } = this.state 
    const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest } = this.props
    console.log("testA", is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest )
    screenSize(is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest)
  }

  setPropsScreenSize() {
    if (window.innerWidth < 430) { //phones /Does it initialize as XXS? 
      this.props.dispatch(screenSizeActions.setScreenSizeXXS())
    }
    else if (window.innerWidth < 577) { //Does it initialize as XS? 
      this.props.dispatch(screenSizeActions.setScreenSizeXS())
    
    } else if (window.innerWidth < 769) { //Does it initialize as SM? 
      this.props.dispatch(screenSizeActions.setScreenSizeSM())
    
    } else if (window.innerWidth < 993) { //Does it initialize as MD? 
      this.props.dispatch(screenSizeActions.setScreenSizeMD())
    
    } else if (window.innerWidth < 1201) {
      this.props.dispatch(screenSizeActions.setScreenSizeLG())
    
    } else if (window.innerWidth < 1400) {
      this.props.dispatch(screenSizeActions.setScreenSizeXL())
    
    } else if (window.innerWidth >= 1400) {
      this.props.dispatch(screenSizeActions.setScreenSizeXXL())
    
    }

  }

  handleVideo(fired) {
    console.log("was fired", fired)
    const videoElement = document.getElementById('home_video');
    if (videoElement && videoElement.playing) {
        // video is already playing so do nothing
    }
    else if (videoElement) {
        videoElement.play();
    } else {
      console.log("Can't find video")
    }
  }

   setupVideo() {
    // document.body.addEventListener('click touchstart', () => this.handleVideo('click touchstart'));
    // window.addEventListener('scroll', () => this.handleVideo('scroll'));
    document.body.addEventListener('mousedown', () => this.handleVideo('mousedown'));

  }

  // componentDidUpdate(prevProps, nextProps) {
  //   console.log("component did update", prevProps, nextProps, this.props)
  //   // if (prevProps.isDarkMode !== this.props.isDarkMode) {
  //   //   this.setState({
  //   //     isDarkMode: this.props.isDarkMode,
  //   //   })
  //   // }

  //   // if (prevProps.accentColor !== this.props.accentColor) {
  //   //   console.log("component did update", prevProps, nextProps, this.props)
  //   //   this.setState({
  //   //     accentColor: this.props.accentColor,
  //   //   })
  //   // }
  // }

  componentWillReceiveProps = (nextProps)=> { //fixes issue where you're routing to this page from Header's Message Icon, and the page doesnt reload. this forces the page to reload.
    if (nextProps.location.key !== this.props.location.key) {
      if (nextProps.location.state && nextProps.location.state.hasOwnProperty('openSignUpModal')) {
        const openSignUpModal = nextProps.location.state.openSignUpModal
        if (openSignUpModal === "register") {
          this.openSignUp()
        } else  if (openSignUpModal === "login") {
          this.openSignIn()
        }

      }
    }
  };


  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  toggleTermsPrivacyModal() {
    this.setState({
      termsPrivacyModal: !this.state.termsPrivacyModal
    });
  }

  toggleSignUpModal() {
    this.setState({
      signUpModal: !this.state.signUpModal
    });
  }

  openSignIn() {
    let url = this.getDashboardUrl()
    if (url) {
      history.push(url)
    } else {
    this.setActiveContainer("")
    this.setState({
      signUpModal: true
    });
    }

  }

  openSignUp() {
    let url = this.getDashboardUrl()
    if (url) {
      history.push(url)
    } else {
      this.setActiveContainer("right-panel-active")
      this.setState({
        signUpModal: true
      })
    }

  }

  setActiveContainer(newState) {
    this.setState({
      activeContainer: newState
    });
  }

  getDashboardUrl() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { loggedIn } = this.props
    // const userIsAgencyAsClient = user && user.agency_as_client;
    const userIsSpecialist = loggedIn && user && user.is_specialist;
    const userIsBusiness = loggedIn && user && user.is_business;
    const userIsAgency = loggedIn && user && user.is_agency
    const userIsUser = loggedIn && user && user.is_user

    let url =  userIsSpecialist ? `/dash/celeb-dashboard/` : 
    userIsBusiness ? `/dash/business-dashboard/` : 
    userIsAgency ? `/dash/agency-dashboard/` : 
    userIsUser ? `/dash/user-dashboard/` 
    : false 
    return url 
  }
  
  
  handleSubmit() {

    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username, password, (success, msg, user, isActive) => {
        if (success === false) {
          this.setState({notificationMessage: [...this.state.notificationMessage, {message: msg, color: 2}] })
          if (isActive === false ) {
            this.reactivateAccountAlert(username)
          }
        } else if (success === true && msg === "show-terms") {
          this.toggleTermsPrivacyModal(user);
          this.setState({ user: user });
        } else {   
            //Success Case--
        } 
      })
      )
    } else if (!password && !username){
      this.setState({notificationMessage: [...this.state.notificationMessage, {message: "Please enter your email + password.", color: 2}] })
    } else if (!password){
      this.setState({notificationMessage: [...this.state.notificationMessage, {message: "Please enter your password.", color: 2}] })
    } else if (!username) {
      this.setState({notificationMessage: [...this.state.notificationMessage, {message: "Please enter your email.", color: 2}] })
    }

  }

  completeLogin(acceptTerms) {
    const { user } = this.state
    const { dispatch } = this.props;
    this.setState({termsPrivacyModal: false})
    if (acceptTerms === true) {
      if (this.state.user !== null || this.state.user !== undefined) {
        dispatch(userActions.completeLogin(user));
        dispatch(userActions.termsAccepted());
      }
    } else {
      this.setState({notificationMessage: [...this.state.notificationMessage, {message: "In order to continue using LiveNyte, you must accept LiveNyte's updated terms of service.", color: 2}] })
    }
    
  }

  responseGoogle(success,response) {
    console.log("GOOGLE RESPONSE", success, response);

    if (success) {
      const creds = {
        company: 'Youtube',
        name: response.profileObj.name,  
        email: response.profileObj.email, 
        gender: "NA",
        id: response.tokenId,
        react: true, 
        login: true,
      }   
      this.props.dispatch(socialMediaActions.socialMediaConnect(creds, (success, user, isActive, email, isRegistered, isMember) => {
        //isActive and email are undefined on success 
        if (success) {
          if (user.terms_accepted === true) {
            this.props.dispatch(userActions.completeLogin(user));
          } else {
            this.toggleTermsPrivacyModal(user);
            this.setState({ user: user });
          }
        } else {
          // isActive can be false or null on fail. 
          if (isActive === false) {
            this.setState({notificationMessage: [...this.state.notificationMessage, {message: user, color: 2}] })
            this.reactivateAccountAlert(email)
          } else {
            if (isMember === true) {
              if (isRegistered === true) {
                const company = 'Google'
                this.showUnauthorizedSocialMediaAlert(company)
              } else {
                console.log('Social Media Connect Failed -> go to Registration ', user)
                this.startSocialRegistration( creds.email, creds.company, creds.id)
              }
            } else {
              console.log('Social Media Connect Failed -> go to Registration ', user)
              this.startSocialRegistration( creds.email, creds.company, creds.id)
            }
            
            // console.log('Social Media Connect Failed -> go to Registration ', user)
            // this.props.dispatch(registerActions.clearRegistrationData())
            // localStorage.setItem('auth-email', creds.email)
            // localStorage.setItem('auth-social', creds.company)
            // localStorage.setItem('auth-socialToken', creds.id)
            // history.replace('/auth/register-page')
            // console.log("In registration case")   
            
          }
        }
      }))
    }
  }

  responseFacebook(response) {
    console.log("FACEBOOK RESPONSE", response);
    if (response && response.hasOwnProperty('userID') && response.hasOwnProperty('accessToken') ) {
      const creds = {
        company: 'Facebook',
        name: response.name, 
        email: response.email, 
        gender: 'NA',
        id: response.userID,
        react: true, 
        login: true,
      };
      this.props.dispatch(socialMediaActions.socialMediaConnect(creds, (success, user, isActive, email, isRegistered, isMember) => {
        //isActive and email are undefined on success 
        if (success) {
          if (user.terms_accepted === true) {
            this.props.dispatch(userActions.completeLogin(user));
          } else {
            this.toggleTermsPrivacyModal(user);
            this.setState({ user: user });
          }
        } else {
          // isActive can be false or null on fail. 
          if (isActive === false) {
            this.setState({notificationMessage: [...this.state.notificationMessage, {message: user, color: 2}] })
            this.reactivateAccountAlert(email)
          } else {

            if (isMember === true) {
              if (isRegistered === true) {
                this.showUnauthorizedSocialMediaAlert(creds.company)
              } else {
                console.log('Social Media Connect Failed -> go to Registration ', user)
                this.startSocialRegistration( creds.email, creds.company, creds.id)
              }
            } else {
              console.log('Social Media Connect Failed -> go to Registration ', user)
              this.startSocialRegistration( creds.email, creds.company, creds.id)
            }
            // console.log('Social Media Connect Failed -> go to Registration ', user)
            // this.props.dispatch(registerActions.clearRegistrationData())
            // localStorage.setItem('auth-email', creds.email)
            // localStorage.setItem('auth-social', creds.company)
            // localStorage.setItem('auth-socialToken', creds.id)
            // history.replace('/auth/register-page')
            // console.log("In registration case")  
          }
        } 
      }))
    }
  }

  showUnauthorizedSocialMediaAlert(company) {
    console.log("In showUnauthorizedSocialMediaAlert")
    this.setState({
      alert: (
        <SweetAlert
          danger
          title={ `${company} has not been authorized to login to this LiveNyte account.`}
          onConfirm={() => this.hideAlert()}
          confirmBtnText="Dismiss"
          confirmBtnBsStyle="primary"
        >
          <span style={{fontSize: "77%"}}>{(`A LiveNyte account exists with the same email address as this ${company} account. ` +
            `However, that LiveNyte account never authorized ${company} as a login method for this account.  ` +
            `For account security reasons, we can't log you in via this method.`)} </span>
            <br/><br/>
            <span style={{fontSize: "77%"}}>{(`Please login to your account with another method.`)}</span>
            <br/><br/>
            <span style={{fontSize: "77%"}}>{(`Once you're logged into your account, you can allow future logins with ${company} by going to Account -> Settings -> Social Login. ` +
            `From there, connect your ${company} account to allow future logins via ${company}.`)}</span>
        </SweetAlert>
      )
    });

    // const title =  `${company} has not been authorized to login to this LiveNyte account.`
    // const message =  (`A LiveNyte account exists with the same email address as this ${company} account. ` +
    //         `However, that LiveNyte account never authorized ${company} as a login method for this account.  ` +
    //         `For account security reasons, we can't log you in via this method.\n\n` +
    //         `Please login to your account with another method.\n\n` +
    //         `Once you're logged into your account, you can allow future logins with ${company} by going to Account -> Settings -> Social Login. ` +
    //         `From there, connect your ${company} account to allow future logins via ${company}.`)
  }

  startSocialRegistration(email, company, id) {
    this.props.dispatch(registerActions.clearRegistrationData())
    localStorage.setItem('auth-email', email)
    localStorage.setItem('auth-social', company)
    localStorage.setItem('auth-socialToken', id)
    history.replace('/auth/register-page')
    console.log("In registration case")  
  }

  responseApple(successApple, response, redirectUri) {
    if (successApple) {
      console.log("responseApple Success", successApple, response)
      const idToken =  response.authorization.id_token
      const appleAccessArgs = {
        id_token: idToken,
      }
      const creds = {
        company: 'Apple',
        gender: 'NA',
        id: idToken,
        react: true, 
        login: true,
      };
      if (response.hasOwnProperty('user') && response.user.hasOwnProperty("name")) {
        const name = response.user.name.firstName + " " + response.user.name.lastName
        creds['name'] = name
        appleAccessArgs['name'] = name
        
      }
      if (response.hasOwnProperty('user') && response.user.hasOwnProperty("email")) {
        creds['email'] = response.user.email
        appleAccessArgs['email'] = response.user.email
      }
      console.log("apple access args", appleAccessArgs)
      this.props.dispatch(userActions.appleGetAccessToken(appleAccessArgs, (success, data) => {
        if (success && data.hasOwnProperty('unregistered') && data.unregistered === false ) {
          console.log("Apple Access Token Success", data)
          this.props.dispatch(socialMediaActions.socialMediaConnect(creds, (successApple, user, isActive, email)  => {
            //isActive and email are undefined on success 
            if (successApple) {
              if (user.terms_accepted === true) {
                this.props.dispatch(userActions.completeLogin(user));
              } else {
                // isActive can be false or null on fail. 
                if (isActive === false) {
                  this.setState({notificationMessage: [...this.state.notificationMessage, {message: user, color: 2}] })
                  this.reactivateAccountAlert(email)
                } else if (isActive === null) {
                  this.toggleTermsPrivacyModal(user);
                  this.setState({ user: user });
                }
              }
            } else {
              console.log("Fail SocialMedia Connect", success)
            }
          }))
        } else if (success && data.hasOwnProperty('unregistered') && data.unregistered === true && data.hasOwnProperty('email') ) {
            console.log('Social Media Connect Failed -> go to Registration ', user)
              this.startSocialRegistration(data.email, creds.company, creds.id)
              console.log("GET APPLE ACCESS TOKEN success. Unregistered user. Go to registration ", data)
              this.props.dispatch(registerActions.clearRegistrationData())
              localStorage.setItem('auth-email', data.email)
              localStorage.setItem('auth-social', creds.company)
              localStorage.setItem('auth-socialToken', creds.id)
              history.replace('/auth/register-page')
              console.log("In registration case")  
        } else {
          console.log("Fail Apple Get Access Token", data)
        }
      }))
    } else {
      console.log("responseApple Failure", successApple, response)
    }
  }

  forgotPasswordAlert() {
    this.setState({
      alert: (
        <SweetAlert
          input
          inputType="email"
          title="Forgot your password?"
          onConfirm={email => this.sendResetPasswordEmail(email)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Send"
          inputPlaceHolder
          placeholder="Enter your email address."
          showCancel
        >
        <p className="text-center text-darkerGray"
           style={{ fontSize: "77%"}}>
          <span> 
            <p> We'll send an email to your email address to help you reset your password.<span className="title"></span>
            </p>
            {/* <br/>Please click the link in the email to continue the account recovery process. */}
          </span>  
        </p>
        </SweetAlert>
      )
    });
  }

  sendResetPasswordEmail(email) {
    this.props.dispatch(authActions.authResetPassword(email, (success, data) => {
      if (success) {
        this.setState({
          alert: (
            <SweetAlert
              success
              title="Check your email"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="primary"
              confirmBtnText="Close"
            >
            <p className="text-center text-darkerGray"
                style={{ fontSize: "77%"}}>
              <span> 
                <span className="h4"> We just sent an email to <span className="title">{email}</span>.
                </span>
                <br/>Please click the link in the email to continue the account recovery process.
              </span>  
            </p>
            </SweetAlert>
          )
        });
      }  else {

      }
    }))

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    const {activeContainer} = this.state
    if (code === 13) {     //13 is the enter keycode
      if (activeContainer === "right-panel-active") {
        this.startEmailRegistration()
      } else {
        this.handleSubmit();
  
      }
    }

  } 

    // notify(place, color, message) {
  notify() {
    const {notificationMessage} = this.state
    // console.log("notify", notificationMessage)
    if (notificationMessage.length === 0) {
      return;
    }
    let type;
    switch (notificationMessage[0].color) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;

      default:
        break;
    }
    let options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {notificationMessage[0].message}
          </div>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    console.log("refs", this.refs)
    if (this.refs.hasOwnProperty('notificationAlert')) {
      let nm = [...notificationMessage]     
      nm.splice(0, 1)
      this.setState({notificationMessage: nm})
      this.refs.notificationAlert.notificationAlert(options);
    } 
  }

  async tryAppleLogIn(e) {
    if (e) {
        e.preventDefault();
    }

    try {
        const data = await window.AppleID.auth.signIn()
        // console.log("initalize AppleSignIn request", data)
        this.responseApple(true, data)
    } catch ( error ) {
        // console.log("Data from AppleSignIn", error)
        this.responseApple(false, error)
    }
    
    // console.log("Data from AppleSignIn", data)
}


startEmailRegistration() {
  const { email } = this.state
  console.log("email", email)
  if (isValidEmail(email)) {
    this.props.dispatch(authActions.authRegistrationEmail(email, (success, data) => {
      if (success) {
        this.sentEmailRegistrationAlert(email)
      }  else {
      }

    }))
  } else {
    this.invalidEmailAlert(email)
  }

}

sentEmailRegistrationAlert(email) {
  this.setState({
    alert: (
      <SweetAlert
        success
        title="Confirm your Email Address"
        onConfirm={() => this.hideAlert()}
        confirmBtnBsStyle="primary"
        confirmBtnText="Close"
      >
      <p className="text-center text-darkerGray"
         style={{ fontSize: "77%"}}>
        <span> 
          <span className="h4"> We just sent an email to <span className="title">{email}</span>.
          </span>
          <br/>Please click the link in the email to continue the registration process.
        </span>  
      </p>
      </SweetAlert>
    ),
    email: ''
  });
}

invalidEmailAlert(email) {
  this.setState({
    alert: (
      <SweetAlert
        danger
        title="Invalid Email Address"
        onConfirm={() => this.hideAlert()}
        confirmBtnBsStyle="primary"
        confirmBtnText="Close"
      >
      <p className="text-center text-darkerGray"
         style={{ fontSize: "77%"}}>
        <span> 
          <h4> 
          <span className="title">{email}</span> is an invalid email address.
          </h4>
          <br/>Please use a valid email address.
        </span>  
      </p>
      </SweetAlert>
    )
  });
}

reactivateAccountAlert(email) {
  this.setState({
    alert: (
      <SweetAlert
        title="Reactivate Account?"
        onConfirm={() => this.reactivateAccountSuccessAlert(email)}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        confirmBtnText="Reactivate"
        showCancel
      >
      <p className="text-center text-darkerGray"
         style={{ fontSize: "77%"}}>
        <span> 
          <span className="h4">Your account is currently deactivated.
          </span>
          <br/> Would you like to reactivate your account?
        </span>  
      </p>
      </SweetAlert>
    )
  });
}

reactivateAccountSuccessAlert(email) {
  this.props.dispatch(authActions.reactivateAccount(email, (success, data) => {
    if (success) {
      this.setState({
        alert: (
          <SweetAlert 
            success
            title="Check your email"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="primary"
            confirmBtnText="Close"
          >
          <p className="text-center text-darkerGray"
            style={{ fontSize: "77%"}}>
            <span> 
              <span className="h4">We just sent a reactivation email to the email address associated with this LiveNyte account.
              </span>
              <br/> Please click the button in the reactivation email to reactivate your LiveNyte account. 
            </span>  
          </p>
          </SweetAlert>
        )
        });
      }
    }))
  }

getLiveNyteCard() {
  const { loggedIn, info, is_md, fanMode } = this.props
  const user = JSON.parse(localStorage.getItem('user'));
  // const userIsAgencyAsClient = user && user.agency_as_client;
  const userIsSpecialist = loggedIn && user && user.is_specialist;
  const userIsBusiness = loggedIn && user && user.is_business;
  const userIsAgency = loggedIn && user && user.is_agency

  return (
      <div className={"pb-4 "}> 
          <h1 className={"display-1 mb-0 livenyte-title " + (is_md ? "text-primary" : "text-primary")}>
            LiveNyte
          </h1>
          <h2 className={"display-4 font-weight-light " + (is_md ? "text-white" : "")}>
            Your time is now.
          </h2>
          <div className="pt-1 mt-1">
            <span className={" " + (is_md ? "text-lighter" : "")} style={{fontSize: "1.13rem"}}> 
            Host events. Book talent & creators. Sell tickets to local fans.
            </span >
          </div>

          {loggedIn && 
          <div className="mt-4 ">
                    <Link to={{pathname: 
                      userIsSpecialist && !fanMode ? `/dash/celeb-dashboard/` : 
                      userIsBusiness  && !fanMode ? `/dash/business-dashboard/` : 
                      userIsAgency  && !fanMode ? `/dash/agency-dashboard/` : `/dash/user-dashboard/`   }} > 
              <Media className="align-items-center cursor ">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  alt="..."
                  className="avatar-sm"
                  src={info && info.image ? info.image : defaultAvatar }
                  onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                />
              </span>
              <Media className="ml-2 ">
                <span className={"mb-0 text-sm font-weight-bold " + (is_md ? "text-white" : "text-default ")}>
                  {info && info.name ? info.name : "Log In"}
                </span>
              </Media>
            </Media>
            <Button
              className="mt-4 bg-gradient-livenyte"
              color="primary"
            >
              Let's go
            </Button>
          </Link>
          </div>
            }
          {!loggedIn && 
          <div className="mt-4 pt-2">
            <Button
              color="primary"
              onClick={this.openSignUp}
              className="bg-gradient-livenyte"
            >
              Get started
            </Button>
          </div>
          }
          {!loggedIn && 
          <div className="pt-3">
            <small onClick={this.openSignIn} className={"text-small cursor-pointer pt-3  "+ (is_md ? "text-white" : "")}>
              Already have an account? <span className="text-primary title">Sign in here.  </span>
            </small>
          </div>
          }
        </div>
  )
}



  render() {
    const { username, password, activeContainer, signupNameFocus, signupEmailFocus, signupPasswordFocus, signinEmailFocus, signinPasswordFocus, isVideoLoaded} = this.state;
    const { loggedIn, info, is_md, updateScreenSizeRequest } = this.props
    const user = JSON.parse(localStorage.getItem('user'));
    // const userIsAgencyAsClient = user && user.agency_as_client;
    const userIsSpecialist = loggedIn && user && user.is_specialist;
    const userIsBusiness = loggedIn && user && user.is_business;
    const userIsAgency = loggedIn && user && user.is_agency
    // const userIsUser = loggedIn && user && user.is_user
    console.log("test props", this.props)
    if (updateScreenSizeRequest === null) {
      return (
        <>
        <header className="header-4 " 
          style={{height:"100vh", 
          // backgroundSize: "cover", backgroundImage: "url(" + (rainbowRoom) + ")"
          background: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + (rainbowRoom) + ")",
          backgroundPosition:"center top",
          backgroundSize:"cover"


        }}
         >
            <div className="header-wrapper">
                  <div className="page-header header-video">
                    <div className="overlay"></div>
          <Container style={{paddingTop: "0vh"}}>
                <Row>
                  <Col className="mx-auto px-0" lg="12">
                    <Card className=" bg-transparent-carbon card-box-shadow mb-0">
                      <CardBody>
                      <div className={"pb-4 "}> 
                        <h1 className={"display-1 mb-0 livenyte-title text-primary"}>
                          LiveNyte
                        </h1>
                        <h2 className={"display-4 font-weight-light text-white inline-block"}>
                          Logging Out 
                        </h2>
                        <div className='sweet-loading inline-block'
                            style={ { transform: "translate(6px,  4px)" }}>
                            <PulseLoader
                              sizeUnit={"px"}
                              size={7}
                              color={'#fff'}
                              
                            />
                        </div>
                      </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
              </div>
              </div>
            </header>
      </>
      )
    }

    return (
      <>
      <div className="bg-white">
        <IndexNavbar linkTextColorDark={true}/>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="main-content">
        {this.notify()}
          {this.state.alert}
          <div className={this.props.is_md ? "" : ""}>
            <header className={"header-4 "}>
              {this.props.is_md && 
              <div className="header-wrapper">
                  <div className="page-header header-video">
                    <div className="overlay"></div>
                      <div className="">
                      {!isVideoLoaded && 
                        <img
                          src={landingPlaceholder}
                          className=" progressiveVideo-tiny objectFitCover z98" 
                          alt="thumb"
                          style={{position:"absolute", right:"0", opacity: isVideoLoaded ? 0 : 1 }}
                        />
                        }
                          <AutoPlaySilentVideo 
                            video={config.commonUrl + "/Common/Landing_Video_Vertical_2.mp4"}
                            // video={landingVideo}
                            className="test-video"
                            isVideoLoaded={isVideoLoaded}
                            onLoadedData={this.onLoadedData}
                            id="home_video"
                          />
                      </div>
                    <Container className="" style={{paddingTop: "0"}}>
                      <Row>
                        <Col className="mx-auto px-0" lg="12">
                          <Card className=" bg-transparent-carbon card-box-shadow mb-0">
                            <CardBody>
                              {this.getLiveNyteCard()}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              }
              {!this.props.is_md && 
                <div>
                  <Row className="align-items-center text-left bg-white mx-0">
                    <Col lg="5" xs="11" className={this.props.is_md ? "mx-auto mt-3 pt-6" : "ml-auto mt-3"}>
                      {this.getLiveNyteCard()}
                      </Col>
                        {
                        <Col className="text-right ml-auto overlay px-0" lg="6" xs="12">
                          <div className="progressiveVideoContainer" >
                            {isVideoLoaded &&
                            <img
                              src={landingPlaceholder}
                              className="headerVideo progressiveVideo-tiny z98 "
                              alt="thumb"
                              onContextMenu={e => e.preventDefault()}
                              style={ {position:"absolute", right:"0", opacity: isVideoLoaded ? 0 : 1 }}
                            />
                            }
                          <AutoPlaySilentVideo 
                            video={config.commonUrl + "/Common/Landing_Video_Vertical_2.mp4"}
                            // video={landingVideo}
                            className="headerVideo"
                            isVideoLoaded={isVideoLoaded}
                            onLoadedData={this.onLoadedData}
                            id="home_video"
                          />
                          </div>
                        </Col>
                        }
                      </Row>
                </div>
                }
            </header>
          </div>
          <section className="pt-6 pb-0 bg-darker" style={{marginTop:"-7px"}}>
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="8" lg="6">
                  <h2 className="display-3 title text-white text-center" >
                    What is <span className="livenyte-title" style={{display: "inline"}}>LiveNyte</span>?
                  </h2>
                  <p className="lead text-lighter font-weight-normal">
                   LiveNyte is a platform where event organizers, talent & creators, and fans come together for memorable experiences. 
                  </p>
                  <h1 className="text-white pb-3 mb-3 text-center">See how it works.</h1>
                </Col>
              </Row>
            </Container>
            <Row className="mx-0">
        <Col md={4} xs={12} className="px-0 mx-0">
        <div className="event-header-container card-lift--hover"  style={ { backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(" + silouhette + ")" } }>
        <span className="wistia_embed wistia_async_ucddbfryrw popover=true popoverAnimateThumbnail=true popoverContent=link"  style={{display:"inline", position:"relative",}}>
          <Row className="mx-0">
                <Col xs={11} sm={12}  className="ml-auto mr-auto text-center " 
                style={ this.props.is_sm || this.props.is_xs ? { marginTop: "130px", marginBottom:"130px" } : {marginTop: "210px", marginBottom:"210px" }}>
                    {/* <Link    to={{pathname: '/auth/creatorLanding' }}> */}
                    <h1 className={this.props.is_xs ? "text-white display-3 " : "text-white display-2" } >
                      Talent
                    </h1>
                    {/* </Link> */}

                    <br/>
                    <Button outline color="spc-white" className="pr-4" size={"lg"} ><i className="fa-solid fa-play pr-3"></i>Play Video</Button>
                </Col>
            </Row>
          </span>
        </div>
        </Col>
        <Col md={4} xs={12} className="px-0 mx-0">
        <div className="event-header-container card-lift--hover"  style={ { backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(" + crowdshot + ")" } }>
        <span className="wistia_embed wistia_async_ynkv1ih4lr popover=true popoverAnimateThumbnail=true popoverContent=link" style={{display:"inline", position:"relative",}}>
          <Row className="mx-0">
                  <Col xs={11} sm={12}  className="ml-auto mr-auto text-center" 
                  style={ this.props.is_sm || this.props.is_xs ? { marginTop: "130px", marginBottom:"130px"  } : {marginTop: "210px", marginBottom:"210px" }}>
                    {/* <Link to={{pathname: '/auth/businessLanding' }}> */}
                      <h1 className={this.props.is_xs ? "text-white display-3" : "text-white display-2" } >Event Organizers</h1>
                    {/* </Link> */}
                    <br/>
                    <Button outline color="spc-white" className="pr-4" size={"lg"} ><i className="fa-solid fa-play pr-3"></i>Play Video</Button>

                  </Col>
                
              </Row>
          </span>
        </div>
        </Col>
        <Col md={4} xs={12} className="px-0 mx-0">
        <div className="event-header-container card-lift--hover"  style={ { backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0.47)), url(" + rainbowRoom + ")" } }>
        <span className="wistia_embed wistia_async_u5bimh17g1 popover=true popoverAnimateThumbnail=true popoverContent=link" style={{display:"inline", position:"relative",}}>
          <Row className="mx-0">
                <Col xs={11} sm={12}  className="ml-auto mr-auto text-center" 
                style={ this.props.is_sm || this.props.is_xs ? {  marginTop: "130px", marginBottom:"130px" } : {marginTop: "210px", marginBottom:"210px" }}>
                  {/* <Link to={{pathname: '/auth/fanLanding' }}> */}
                  <h1 className={this.props.is_xs ? "text-white display-3" : "text-white display-2" } >Fans</h1>
                  {/* </Link> */}
                  <br/>
                  <Button outline color="spc-white" className="pr-4" size={"lg"} ><i className="fa-solid fa-play pr-3"></i>Play Video</Button>
                </Col>
            </Row>
          </span>
        </div>
        </Col>
        </Row>
      </section>



          <section className="py-6 bg-white">
            <div className="landingContainer">
            <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                <Card className="shadow border-0">
                      <CardImg
                          alt={"..."}             
                          src={config.commonUrl + "/Common/musician_preview.mp4"}
                          onError={(e)=>{e.target.onerror = null; e.target.src=musicianVideoFallback}}
                          top
                      />
                </Card>
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>Talent & Creators </h1>
                    <h2 className="font-weight-light">A better way to get booked.</h2>
                    <div className="pb-2 special-left-underline-livenyte" style={{paddingTop:"2px"}}></div>                    
                    <p className="mt-2"> LiveNyte provides talent & creators the tools to run their live event bookings & digital presence without agents, managers, or other gatekeepers. 
                    <br/><br/>
                    <b>On LiveNyte, you can be your own manager, get booked at events, plan your own tour, build a website EPK, and sell exclusive content subscriptions.</b>
                    <br/><br/>
                    <h2 className="font-weight-bold">Are you ready to go pro, grow, get more opportunities, and get booked? </h2>

                    {/* <br/><br/>
                    You can put your best forward to these event organizers by building your own website EPK through LiveNyte. And, you can create exclusive content subscriptions for your LiveNyte fans to hype them up for your next event.
                    <br/><br/>
                    Your followers & subscribers on LiveNyte help increase your placement & visiblity to local event organizers who are looking to book people like you for their events. */}
                    </p>
                    <Row className="pt-3">
                    <Col xs={5} sm={5} md={6} lg={6} xl={5}>
                      <Button color="primary" block className={" bg-gradient-livenyte" } size="lg" onClick={this.openSignUp}>
                            Sign up
                        </Button>
                      </Col>
                      <Col xs={5} sm={5} md={6} lg={6} xl={5}>
                      <Link to={{pathname: '/auth/creatorLanding' }}>
                          <Button color="primary" block outline className="" size={"lg"} >Learn more</Button>
                        </Link>
                        </Col>
                      </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          <section className="py-6 bg-white">
            <div className="landingContainer">
              <Row className="row-grid align-items-center">
              <Col md="6">
                {/* <img
                    alt="..."
                    className="img-fluid"
                    src={eventOrganizer}
                  /> */}
                <Card className="shadow border-0 px-2">
                    <CardImg
                        alt={"..."}             
                        src={eventOrganizer}
                        top
                    />
                  </Card>
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1 className="text-left ">
                        Businesses & Event Organizers
                    </h1>
                    <h2 className="font-weight-light">Making event promotion & talent-booking easy. </h2>
                    <div className="pb-2 special-left-underline-tertiary" style={{paddingTop:"2px"}}></div>                    
                    <p className="mt-2"> LiveNyte streamlines events by making the talent-booking, ticket sales, and promotion easy.
                    <br/><br/> 
                    <b>When you host an event and book talent on LiveNyte, we promote your event to local live event fans and your booked talent's fans.</b>
                    <br/><br/>
                    </p>
                    <h2 className="pl-0">Are you ready to plug your events into the live event community?</h2>
                  </div>
                  <Row className="pt-3">
                    <Col xs={5} sm={5} md={6} lg={6} xl={5}>
                      <Button color="tertiary" block className={" bg-gradient-tertiary" } size="lg" onClick={this.openSignUp}>
                            Sign up
                        </Button>
                      </Col>
                      <Col xs={5} sm={5} md={6} lg={6} xl={5}>
                    <Link to={{pathname: '/auth/businessLanding' }}>
                        <Button color="tertiary" block outline className="" size={"lg"} >Learn more</Button>
                      </Link>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </div>
          </section>
          <section className="py-6 bg-white">
            <div className="landingContainer">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <Card className="shadow border-0 px-2">
                      <CardImg
                          alt={"..."}             
                          src={rainbowRoom}
                          top
                      />
                    </Card>
                </Col>
                <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                    <h1 className="text-left ">
                       Fans
                    </h1>
                    <h2 className="font-weight-light">A better fan experience.</h2>
                    <div className="pb-2 special-left-underline-info" style={{paddingTop:"2px"}}></div>                    
                    <p className="mt-2"> LiveNyte fans have a better live event experience from start to finish.
                      <br/><br/>
                      Stay up-to-date with opportunities to engage with your favorite talent & creators. By following them and subscribing to their content, you can help them get booked at events in your area. 
                      <br/><br/>
                      When they get booked, you get notified to check out the event & get tickets. LiveNyte tickets cannot be scalped. 
                      <br/><br/>
                    </p>
                    <h2 className="pl-0">Are you ready to join the live event community?</h2>
                  </div>
                  <Row className="pt-3">
                    <Col xs={5} sm={5} md={6} lg={6} xl={5}>
                    <Button color="info" block className={" bg-gradient-info" } size="lg" onClick={this.openSignUp}>
                            Sign up
                        </Button>
                      </Col>
                      <Col xs={5} sm={5} md={6} lg={6} xl={5}>
                      <Link to={{pathname: '/auth/fanLanding' }}>
                          <Button color="info" block outline className="" size={"lg"} >Learn more</Button>
                        </Link>
                        </Col>
                  </Row>  
                </Col>
              </Row>
            </div>
          </section>

          {/* <div className="mt-8 bg-dark"> 
            <section className="section section-lg pt-0 mt--8" style={{transform:"translateY(-96px)"}}>
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Row>
                      <Col lg="4" className={is_md ? "pt-3" : ""}>
                        <Card className="card-lift--hover shadow border-0">
                        <CardImg
                            alt={"..."}             
                            src={config.commonUrl + "/Common/musician_preview.mp4"}
                            onError={(e)=>{e.target.onerror = null; e.target.src=musicianVideoFallback}}
                            top
                        />
                          <CardBody className="py-5">
                            <h4 className="h2 text-primary text-uppercase">
                             Build a great website
                            </h4>
                            <p className="description mt-3">
                            Add photos, videos, and blog to showcase your talent. Your event bookings and subscriptions sold through LiveNyte will automatically be added to your website.
                            </p>
                            <h5 className="surtitle text-dark pt-2 h5-standard">nyte.live/<span className="title text-primary">YOUR_USERNAME</span></h5>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4" className={is_md ? "pt-3" : ""}>
                        <Card className="card-lift--hover shadow border-0">
                        <CardImg
                            alt={"..."}             
                            src={createExample}
                            style={{objectFit: "cover"}}
                            onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
                            top
                        />
                          <CardBody className="py-5">
                

                            <h4 className="h2 text-tertiary text-uppercase">
                              Set up your store
                            </h4>

                            <p className="description mt-3 ">
                            Create and sell digital content and subscriptions such as podcasts, newsletters, blogs, music, or for anything else you want. <br/><br/>
                            </p>
                            <h5 className="surtitle text-dark pt-2 h5-standard ">Build your <span className="title text-tertiary">online presence.</span></h5>

                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4" className={is_md ? "pt-3" : ""}>
                        <Card className="card-lift--hover shadow border-0">
                        <iframe 
                          className="card-img-top wistia_embed" 
                          style={{aspectRatio:"16/9"}}
                          allowtransparency="true" 
                          title="Wistia video player" 
                          allowFullscreen frameborder="0"
                         scrolling="no" 
                         name="wistia_embed" 
                         src="https://fast.wistia.net/embed/iframe/ucddbfryrw" 
                         width="auto" 
                         height="auto"
                         
                         ></iframe>
                          <CardBody className="py-5">
                            <h4 className="h2 text-danger text-uppercase">
                            Get booked at live events
                            </h4>
                            <p className="description mt-3">
                            Tell us when and where you’re available. And, we’ll show you as candidates to book for events.
                            Save time and energy filtering through the noise with our hassle-free event booking system.
                            </p>
                            <h5 className="surtitle text-dark pt-2 h5-standard ">Connect better <span className="title text-danger"> with your fans.</span></h5>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </div> */}
          {/* <section className="pt-7 pb-6 bg-gradient-livenyte mt--2">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="9" lg="8">
                  <h1 className="display-3 title text-white" >
                    <span style={{display: "inline"}} className="livenyte-title">LiveNyte</span> 
                  </h1>
                  <h2 className="text-lighter mt-4 font-weight-light">
                    Host events &nbsp;|&nbsp; Book talent &nbsp;|&nbsp; Sell tickets &nbsp;|&nbsp; Promote to local fans 
                  </h2>
                </Col>
              </Row>
            </Container>
          </section> */}

        {/* <section className="py-6 bg-white">
            <Container>
              <Row className="row-grid align-items-center">
          
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>Available on Web & Mobile</h1>
                    <p>
                    Take the <i className="title">LiveNyte</i> experience anywhere you go. 
                    </p>
                    <Row className="pt-2">
                      <Col xs={5} sm={5} md={7} lg={6} xl={5}>
                        <Button color="primary" block className={" bg-gradient-livenyte" } size="lg" onClick={this.openSignUp}>
                            Sign Up
                        </Button>
                      </Col>
                      <Col xs={6} sm={6} md={5} lg={6}>
                        <a href="https://itunes.apple.com/us/app/livenyte/id1378430467?mt=8" 
                              className={"btn-app"}>
                              <img src ={appStore} alt="App Store Button" /> 
                          </a>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src= {mobileImage} 
                  />
                </Col>
              </Row>
            </Container>
          </section> */}
        <FeaturedCelebsSection/>
        <FeaturedEventsSection/>
          {/* <section className="py-6 bg-white">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    style={{maxWidth: "400px"}}
                    src={rocket}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>Why choose us?</h1>
                    <p> We're building LiveNyte into an all-in-one platform and solution for connecting creators, businesses, and their fans. Everything that we have today is just a starting point that we'll build upon and expand from.  
                    </p>
                    <h5 className="surtitle text-tertiary pt-2 h5-standard cursor-pointer" onClick={this.openSignUp} >Let's build that future together. </h5>
                 
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6 bg-white">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={lightbulb}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>How much does LiveNyte cost?</h1>
                    <p>
                    There are no subscription fees to use LiveNyte. We only collect small fees when transactions go through the platform. These fees help cover the cost for maintaing the platform and building new features. 
                    <h5 className="surtitle text-tertiary pt-2 h5-standard cursor-pointer" onClick={this.openSignUp}> When it works for you, it works for us.</h5>
                  
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-6 bg-white">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2 text-center" md="6">
                  <img
                    alt="..."
                    className="img-fluid rounded-circle "
                    src={livenyteLogo}
                    style={{maxWidth:"360px"}}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>About us</h1>
                    <Linkify> 
                    <p>
                      We're a team based in the San Franisco Bay Area. We're always looking for passionate people who believe in this future.
                      If you're interested in working with us, feel free to introduce yourself over email to connect@livenyte.com.
                    </p>
                    </Linkify>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}
          <Modal
              isOpen={this.state.signUpModal}
              toggle={this.toggleSignUpModal}
              className="modal-notice register-page"
              size="lg"
              fade={false}
            >
              <ModalHeader toggle={this.toggleSignUpModal} style={{height:"600px"}}>
              <Container className={activeContainer}>
                  <div className="form-container sign-up-container" style={this.props.is_xs && this.state.activeContainer === "right-panel-active"  ? {width: "100%", transform:"translateX(0%)", WebkitTransform: "translateX(0%)"} : this.props.is_xs  && this.state.activeContainer === "" ? {width: "0%", display:"none"} : {} }>
                    {/* <img style={{height: "30px", width:"auto"}} src={WhiteLogo}/> */}
                    <Form role="form" onSubmit={(e) => e.preventDefault()}>
                    <h1 className="livenyte-title text-primary" style={{fontSize:"2rem"}}>LiveNyte</h1>
                    <h3 className="surtitle" >Sign up</h3>
                    <div className="my-2 special-underline-lightGray" />
                      <div className="social-container">
                      <FacebookLogin
                              appId="197943750696053" //REAL
                              autoLoad={false}
                              fields="name,email,picture"
                              scope="public_profile"
                              redirectUri={config.serverUrl }
                              isMobile={false} 
                              disableMobileRedirect={true}
                              callback={rsp => this.responseFacebook(rsp)}
                              render={renderProps => (
                                <Button color="facebook" style={{paddingTop:"0.313rem"}} size="sm" type="button" onClick={renderProps.onClick} >
                                <span className="btn-inner--icon">
                                    <i className="fa-brands fa-facebook"  style={{top:"1px", fontSize:"0.85rem"}}></i>
                                </span>
                            </Button>
                            //     <Button
                            //     className="btn-neutral btn-icon mr-0"
                            //     color="default"
                            //     style={{width:"120px"}}
                            //     onClick={renderProps.onClick}
                            //     size="sm"
                            //   >

                            //     <div>
                            //       <div className="inline-block" style={{float:"left"}}>
                            //         <span className="btn-inner--icon mr-1">
                            //         <img
                            //           alt="Apple"
                            //           style={{transform:"translateY(-.11rem"}}
                            //           src={require("assets/img/icons/common/facebook.png")}
                            //         />
                            //       </span>
                            //       </div>
                            //       <div className="inline-block"> 
                            //         <span className="btn-inner--text " style={{color:"#1877f2"}}>Facebook</span>
                            //       </div>
                            //     </div>     




                            //     <span className="btn-inner--icon mr-1">
                            //       <img
                            //         alt="Facebook"
                            //         src={require("assets/img/icons/common/facebook.png")}
                            //       />
                            //     </span>
                            //     <span className="btn-inner--text" style={{color:"#1877f2"}}>Facebook</span>
                            // </Button>
                              )}
                            />
                      {/* <br/> */}
                        <GoogleLogin
                            clientId="1007593483972-gr74opa14suioe6bkvp0etop08pbfjoj.apps.googleusercontent.com" //REAL
                            onSuccess={rsp => this.responseGoogle(true, rsp)}
                            onFailure={rsp => this.responseGoogle(false, rsp)} 
                            redirectUri={config.serverUrl}
                            // isSignedIn ={true}
                            cookiePolicy={'single_host_origin'}
                            render={renderProps => (                  
                              <Button color="white" size="sm" style={{paddingTop:"0.313rem"}}  type="button" className="" onClick={renderProps.onClick}>
                                <span className="btn-inner--icon" >
                                  <i className="fa-brands fa-google" style={{top:"1px"}}></i>
                              </span>
                            </Button>
                        //     <Button
                        //     className="btn-neutral btn-icon mt-3 mr-0"
                        //     color="default"
                        //     size="sm"
                        //     style={{width:"120px"}}
                        //     onClick={renderProps.onClick}> 
                        //         <div>
                        //           <div className="inline-block" style={{float:"left"}}>
                        //             <span className="btn-inner--icon mr-1">
                        //             <img
                        //               alt="Google"
                        //               style={{transform:"translateY(-.11rem"}}
                        //               src={require("assets/img/icons/common/google.svg")}
                        //               />
                        //           </span>
                        //           </div>
                        //           <div className="inline-block"> 
                        //             <span className="btn-inner--text text-gray">Google</span>
                        //           </div>
                        //         </div>            



                        //         <span className="btn-inner--icon mr-1">
                        //           <img
                        //           alt="Google"
                        //           src={require("assets/img/icons/common/google.svg")}
                        //           />
                        //         </span>
                        //         <span className="btn-inner--text text-gray">Google</span>     
                        // </Button>
                            )}
                          />
                  
                          {/* <br/> */}
                          {isProductionBuild() && 
                        <AppleSignIn
                              config= {{
                                clientId: "com.livenyte.livenyte",
                                redirectURI: (config.serverUrl),
                                responseType: "code",
                                responseMode: "form_post", 
                                scope: 'name email',
                                usePopup: true
                              }}
                              onSuccess={rsp => this.responseApple(true, rsp)} 
                              onFailure={rsp => this.responseApple(false, rsp)} 
                              render={renderProps => (
                                <Button  color="dark" size="sm" type="button" onClick={renderProps.onClick}>
                                  <span className="btn-inner--icon">
                                    <i className="fa-brands fa-apple text-white" style={{fontSize:"1rem"}}></i>
                                  </span>
                              </Button>
                            //   <Button
                            //   className="btn-neutral btn-icon mt-3 mr-0"
                            //   color="default"
                            //   style={{width:"120px"}}
                            //   size="sm"
                            //   onClick={renderProps.onClick}
                            //   >
                            //     <div>
                            //       <div className="inline-block" style={{float:"left"}}>
                            //         <span className="btn-inner--icon mr-1">
                            //         <img
                            //           alt="Apple"
                            //           style={{height:"20px", width:"16px", transform:"translateY(-.11rem"}}
                            //           src={require("assets/img/icons/common/apple_icon.svg")}
                            //         />
                            //       </span>
                            //       </div>
                            //       <div className="inline-block"> 
                            //         <span className="btn-inner--text text-dark">Apple</span>
                            //       </div>
                            //     </div>  
                            //   <span className="btn-inner--icon mr-1">
                            //     <img
                            //       alt="Apple"
                            //       style={{height:"20px", width:"16px"}}
                            //       src={require("assets/img/icons/common/apple_icon.svg")}
                            //       />
                            //   </span>
                            // <span className="btn-inner--text text-dark" style={{color:"#1877f2"}}>Apple</span>                    
                          // </Button>
                              )}
                          />
                        }

                      </div>
                      <span className="h5 mb-4 pt-2 surtitle text-default" style={{fontWeight:"400",}}>
                        or with email
                      </span>
                      <FormGroup className={"mb-3 " + signupEmailFocus} style={{maxWidth: "300px"}} >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            value = {this.state.email}
                            onChange={this.handleChange}
                            onKeyPress={this.enterPressed.bind(this)}
                            // onFocus={() => this.setSignupEmailFocus("focused")}
                            // onBlur={() => this.setSignupEmailFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <Button color="primary mt-3 bg-gradient-livenyte" onClick={() => this.startEmailRegistration()}>Sign Up</Button>
                      {this.props.is_xs && 
                      
                      <span className="h4 mt-4 pt-2">
                        Already have an account?
                      <Button
                          className="ml-2 "
                          color="primary"
                          id="signIn"
                          size="sm"
                          outline
                          onClick={() => this.setActiveContainer("")}
                        >
                          Sign in
                        </Button>
                        </span>
                        }
                    </Form>
                  </div>
                  
                  <div className="form-container sign-in-container"style={this.props.is_xs && this.state.activeContainer === "right-panel-active"  ? {width: "0%", display:"none"} : this.props.is_xs  && this.state.activeContainer === "" ? {width: "100%"} : {} }>
                    {/* <img style={{height: "30px", width:"auto"}} src={LiveNyteLogo}></img> */}
                    <Form role="form" onSubmit={(e) => e.preventDefault()}>
                      <h1 className="livenyte-title text-primary" style={{fontSize:"2rem"}}>LiveNyte</h1>
                      <h3 className="surtitle " >Sign In</h3>
                      <div className="my-2 special-underline-lightGray" />
                      <div className="social-container">
                          <FacebookLogin
                                appId="197943750696053" //REAL
                                autoLoad={false}
                                fields="name,email,picture"
                                scope="public_profile"
                                isMobile={false} 
                                disableMobileRedirect={true}
                                redirectUri={config.serverUrl}
                                callback={rsp => this.responseFacebook(rsp)}
                                render={renderProps => (
                                  <Button color="facebook" style={{paddingTop:"0.313rem", fontSize:"0.85rem"}} size="sm" type="button" onClick={renderProps.onClick} >
                                <span className="btn-inner--icon">
                                      <i className="fa-brands fa-facebook" style={{top:"1px"}}></i>
                                    </span>
                                  </Button>
                              //   <Button
                              //     className="btn-neutral btn-icon mr-0"
                              //     color="default"
                              //     size="sm"
                              //     style={{width:"120px"}}
                              //     onClick={renderProps.onClick}
                              //   >

                              //   <div>
                              //     <div className="inline-block" style={{float:"left"}}>
                              //       <span className="btn-inner--icon mr-1">
                              //       <img
                              //         alt="Apple"
                              //         style={{transform:"translateY(-.11rem"}}
                              //         src={require("assets/img/icons/common/facebook.png")}
                              //       />
                              //     </span>
                              //     </div>
                              //     <div className="inline-block"> 
                              //       <span className="btn-inner--text " style={{color:"#1877f2"}}>Facebook</span>
                              //     </div>
                              //   </div>     


                              //     <span className="btn-inner--icon mr-1">
                              //       <img
                              //         alt="Facebook"
                              //         src={require("assets/img/icons/common/facebook.png")}
                              //       />
                              //     </span>
                              //     <span className="btn-inner--text" style={{color:"#1877f2"}}>Facebook</span>
                              // </Button>
                                )}
                              />
               
                              {/* <br/> */}
                              
                        <GoogleLogin
                            clientId="1007593483972-gr74opa14suioe6bkvp0etop08pbfjoj.apps.googleusercontent.com" //REAL
                            onSuccess={rsp => this.responseGoogle(true, rsp)}
                            onFailure={rsp => this.responseGoogle(false, rsp)} 
                            redirectUri={config.serverUrl }
                            // isSignedIn ={true}
                            cookiePolicy={'single_host_origin'}
                            render={renderProps => (                  
                              <Button color="white" size="sm" style={{paddingTop:"0.313rem"}}  type="button" className="" onClick={renderProps.onClick}>
                                <span className="btn-inner--icon" >
                                  <i className="fa-brands fa-google" style={{top:"1px"}}></i>
                                </span>
                              </Button>
                        //     <Button
                        //     className="btn-neutral btn-icon mt-3 mr-0"
                        //     color="default"
                        //     size="sm"
                        //     // href="#pablo"
                        //     style={{width:"120px"}}
                        //     onClick={renderProps.onClick}>     
                        //       <div>
                        //           <div className="inline-block" style={{float:"left"}}>
                        //             <span className="btn-inner--icon mr-1">
                        //             <img
                        //               alt="Google"
                        //               style={{transform:"translateY(-.11rem"}}
                        //               src={require("assets/img/icons/common/google.svg")}
                        //               />
                        //           </span>
                        //           </div>
                        //           <div className="inline-block"> 
                        //             <span className="btn-inner--text text-gray">Google</span>
                        //           </div>
                        //         </div>     



                                
                        //         <span className="btn-inner--icon mr-1">
                        //           <img
                        //           alt="Google"
                        //           src={require("assets/img/icons/common/google.svg")}
                        //           />
                        //         </span>
                        //         <span className="btn-inner--text text-gray">Google</span>                
                        // </Button>
                        )}
                        />

                        {/* <br/> */}
                              
                        {isProductionBuild() && 
                              <Button  color="dark" size="sm" type="button" onClick={(e) => this.tryAppleLogIn(e)}>
                                <span className="btn-inner--icon">
                                  <i className="fa-brands fa-apple" style={{fontSize:"1rem"}}></i>
                                </span>
                            </Button> 
                          //     <Button
                          //     className="btn-neutral btn-icon mt-3 mr-0"
                          //     color="default"
                          //     style={{width:"120px"}}
                          //     size="sm"
                          //     onClick={(e) => this.tryAppleLogIn(e)}
                          //     >                                
                          //       <span className="btn-inner--icon mr-1">
                          //       <img
                          //         alt="Apple"
                          //         src={require("assets/img/icons/common/apple_icon.svg")}
                          //         />
                          //     </span>
                          //   <span className="btn-inner--text text-dark" style={{color:"#1877f2"}}>Apple</span>
                                
                          //       <div>
                          //         <div className="inline-block" style={{float:"left"}}>
                          //           <span className="btn-inner--icon mr-1">
                          //           <img
                          //             alt="Apple"
                          //             style={{height:"20px", width:"16px", transform:"translateY(-.11rem"}}
                          //             src={require("assets/img/icons/common/apple_icon.svg")}
                          //           />
                          //         </span>
                          //         </div>
                          //         <div className="inline-block"> 
                          //           <span className="btn-inner--text text-dark">Apple</span>
                          //         </div>
                          //       </div>                     
                          // </Button>
                              }
                              
                      </div>
                      <span className="text-default h5 pt-2 mb-4 surtitle text-default" style={{fontWeight:"400" }}>or with email + password</span>
                      <FormGroup className={"mb-3 " + signinEmailFocus}  style={{maxWidth: "300px"}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="username"
                            autocomplete="username"
                            value={username}
                            onChange={this.handleChange}
                            // onFocus={() => this.setSigninEmailFocus("focused")}
                            // onBlur={() => this.setSigninEmailFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={signinPasswordFocus}  style={{maxWidth: "300px"}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            autocomplete="current-password"
                            value={password}
                            onChange={this.handleChange}
                            onKeyPress={this.enterPressed.bind(this)}
                            // onFocus={() => this.setSigninPasswordFocus("focused")}
                            // onBlur={() => this.setSigninPasswordFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <Button color="primary" className="mt-2 bg-gradient-livenyte"  onClick={()=> this.handleSubmit()}>
                        Sign In
                      </Button>
                      <p className=" text-underline cursor-pointer" onClick={() =>this.forgotPasswordAlert()}>
                        Forgot your password?
                      </p>
                      {this.props.is_xs && 
                      <span className="h4">
                        Don't have an account?
                      <Button
                          className="ml-2 "
                          color="primary"
                          id="signUp"
                          size="sm"
                          outline
                          onClick={() => this.setActiveContainer("right-panel-active")}
                        >
                          Sign Up
                        </Button>
                        </span>
                        }
                    </Form>
                  </div>
                  {!this.props.is_xs && 
                  <div className="overlay-container">
                    <div className="overlay">
                      <div className="overlay-panel overlay-left">
                        <h1 className="text-white">Welcome back!</h1>
                        <p>
                          To get back into your LiveNyte account, please login here. 
                        </p>
                        <Button
                          className="btn-neutral"
                          color="default"
                          id="signIn"
                          size="sm"
                          onClick={() => this.setActiveContainer("")}
                        >
                          Sign In
                        </Button>
                      </div>
                      <div className="overlay-panel overlay-right">
                        <h1 className="text-white">Hey there!</h1>
                        <p>We're excited to start this journey with you!</p>
                        <Button
                          className="btn-neutral"
                          color="default"
                          id="signUp"
                          size="sm"
                          onClick={() => this.setActiveContainer("right-panel-active")}
                        >
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  </div>
                  }
                </Container>
              </ModalHeader>
            </Modal>
        
            <Modal
              isOpen={this.state.termsPrivacyModal}
              toggle={this.toggleTermsPrivacyModal}
              className="modal-notice"
              size="lg"
              fade={false}
            >
              <ModalHeader toggle={this.toggleTermsPrivacyModal}>
              Terms & Privacy 
              </ModalHeader>
              <ModalBody className="text-center">
                <Nav
                    pills
                    className="nav-pills-primary justify-content-center"
                  >
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={this.state.hTabs === "ht1" ? "active" : ""}
                      onClick={() => this.setState({ hTabs: "ht1" })}
                    >
                      Terms of Service
                    </NavLink>
                  </NavItem>
                  <NavItem className="cursor-pointer">
                    <NavLink
                        className={this.state.hTabs === "ht2" ? "active" : ""}
                        onClick={() => this.setState({ hTabs: "ht2" })}
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  className="tab-space"
                  activeTab={this.state.hTabs}
                  style={{marginTop:"15px"}}
                  >
                  <TabPane tabId="ht1">
                    <div className="table-full-width table-responsive">
                    <ResponsiveEmbed src={config.commonUrl + config.termsLocalPath}></ResponsiveEmbed>
                    </div>
                  </TabPane>
                  <TabPane tabId="ht2">
                    <div className="table-full-width table-responsive">
                      <ResponsiveEmbed src={config.commonUrl + config.privacyLocalPath} ></ResponsiveEmbed>
                    </div>
                  </TabPane>
                </TabContent>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col md={8}>  
                    <p className="title"> By clicking 'Agree', I agree to LiveNyte's Terms of Service, and I have read LiveNyte's Privacy Policy. </p>
                  </Col>
                  <Col md={4}>  
                  <div className="float-right">  
                    <Button
                        color="danger"
                        className ="title"
                        onClick={() => this.completeLogin(false)}
                      >
                      Reject
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        color="success"
                        className ="title"
                        onClick={() => this.completeLogin(true)}
                      >
                      Agree
                    </Button>
                  </div>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </div>
        <Row>
          
        </Row>
        <AuthFooter />
        <CookieManager/>
        </div>
      </>
    );
  }
}

//export default LoginPage;
function mapStateToProps(state) {
  const { authentication, memberInfo, screenSizeData, fanModeData } = state;
  const { loggingIn, loggedIn } = authentication;
  const { info, } = memberInfo;
  const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;
  const { fanMode } = fanModeData;

  return {
      loggingIn,
      loggedIn,
      info,
      is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest,
      fanMode
  };
}

const connectedIndexView = connect(mapStateToProps)(IndexView);
export { connectedIndexView as IndexView }; 