
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import { connect } from 'react-redux';

import {
  Collapse,
  NavbarBrand,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button
  // Button
} from "reactstrap";
import Headroom from "headroom.js";
import defaultAvatar from "assets/img/default-avatar.png"

import LiveNyteColoredBrand from "assets/img/brand/livenyte-colored.jpg"
import LiveNyteWhiteBrand from "assets/img/brand/livenyte-white.png"

import { history } from "_helpers"

class IndexNavbar extends React.Component {
  constructor(props) {
    super(props);
    const { linkTextColorDark } = props 
    this.state = {
      headRoomOnTop: true,
      linkTextColorDark: linkTextColorDark ? linkTextColorDark : false,
      collapseOpen: false,

     };
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.closeCollapse = this.closeCollapse.bind(this)
     
  }
  componentDidMount(){
    // const { navigationBarColor } = this.state
    let headroom = new Headroom(document.getElementById("dark-navbar-main"), {
      onNotTop : () => {
        this.setState({
          headRoomOnTop: false 
        })
        console.log("headroom on TOp = false")
      },
      onTop : () => {
        this.setState({
          headRoomOnTop: true 
        })
        console.log("headroom on TOp = true")

      }
    } );
    headroom.init();
  }

  handleButtonClick(state) {
    this.closeCollapse()
    history.push({
      pathname: '/',
      state: {openSignUpModal: state}
    })
  }

  toggleCollapse() {
    const { collapseOpen } = this.state
    this.setState({collapseOpen: !collapseOpen });
  };

  closeCollapse() {
    this.setState ({collapseOpen: false})
  }


  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { loggedIn, info, is_md, fanMode} = this.props
    const { linkTextColorDark, headRoomOnTop, collapseOpen } = this.state 
    // const userIsAgencyAsClient = user && user.agency_as_client;
    const userIsSpecialist = user && user.is_specialist;
    const userIsBusiness = user && user.is_business;
    const userIsAgency = user && user.is_agency
    return (
      <>
        <Navbar
        className={"navbar-horizontal navbar-main headroom bg-transparent navbar-light"}
          expand="lg"
          id="dark-navbar-main"
        >
          <Container>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img
                alt="..."
                style={{height: "30px"}}
                src={ (linkTextColorDark  && !is_md) || !headRoomOnTop ? LiveNyteColoredBrand  : LiveNyteWhiteBrand}
              />
            </NavbarBrand>
            <button
              // aria-controls="navbar-collapse"
              // aria-expanded={false}
              // aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navbar-collapse"
              // data-toggle="collapse"
              id="navbar-collapse"
              type="button"
              onClick={() => this.toggleCollapse(!collapseOpen)}

            >
              <span className="navbar-toggler-icon" />
            </button>
            <Collapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
              isOpen={collapseOpen}

            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/" >
                      <img
                        alt="..."
                        src={LiveNyteColoredBrand}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      // aria-controls="navbar-collapse"
                      // aria-expanded={false}
                      // aria-label="Toggle navigation"
                      className="navbar-toggler"
                      onClick={() => this.toggleCollapse(!collapseOpen)}
                      // data-target="#navbar-collapse"
                      // data-toggle="collapse"
                      id="navbar-collapse"
                      // type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className={"mr-auto " + (is_md ? "pl-2 ml-1" : "") }  navbar>

                <NavItem >
                  <NavLink onClick={() => this.closeCollapse() } to="/auth/creatorLanding" tag={Link}>
                    <a 
                    href="#pablo" className={"h5 h5-standard  surtitle " + (linkTextColorDark || this.props.is_md || !headRoomOnTop ? "" : "text-white")}>Talent & Creators
                    </a>
                  </NavLink>
                </NavItem> 
                <NavItem>
                  <NavLink onClick={() => this.closeCollapse() } to="/auth/fanLanding" tag={Link}>
                    <a 
                    href="#pablo" className={"h5 h5-standard  surtitle " + (linkTextColorDark || this.props.is_md || !headRoomOnTop ? "" : "text-white")}>Fans</a>
                  </NavLink>
                </NavItem> 
                <NavItem>
                  <NavLink onClick={() => this.closeCollapse() } to="/auth/businessLanding" tag={Link} >
                    <a 
                    
                    href="#pablo" className={"h5 h5-standard  surtitle " + (linkTextColorDark || this.props.is_md || !headRoomOnTop ? "" : "text-white")}>Businesses</a>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => this.closeCollapse() } to="/auth/pricing" tag={Link}>
                  <a
                  href="#pablo" className={"h5 h5-standard  surtitle " + (linkTextColorDark || this.props.is_md || !headRoomOnTop ? "" : "text-white")}>Pricing</a>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink onClick={() => this.closeCollapse() } to="/auth/eventsLanding" tag={Link}>
                  <a
                  href="#pablo" className={"h5 h5-standard  surtitle " + (linkTextColorDark || this.props.is_md || !headRoomOnTop ? "" : "text-white")}>Events</a>
                  </NavLink>
                </NavItem> */}
              </Nav>
              <hr className="d-lg-none" />
              <Nav className="align-items-lg-center ml-lg-auto mx-1 px-1 " navbar>
                {is_md && 
                <Row>
                  {!loggedIn  && 
                  <Col xs={6} className="ml-auto text-left">
                    <Button color="primary" block size="lg" outline onClick={() => this.handleButtonClick("login")}>
                        Log In
                    </Button>
                  </Col>
                  }
                  {!loggedIn  && 
                  <Col xs={6} className="ml-auto text-right">
                    <Button color="primary" block className={" bg-gradient-livenyte" } size="lg" onClick={() => this.handleButtonClick("register")}>
                        Sign Up
                    </Button>
                  </Col>
                  }
                  
                </Row>
              }
              {!loggedIn && !is_md && 
                <NavItem>
                  <Button color="primary" size={this.props.is_md ? "md" : "md"} outline onClick={() => this.handleButtonClick("login")}>
                      Log In
                  </Button>
                </NavItem>
                }
                {!loggedIn && !is_md && 
                <NavItem>
                  <Button color="primary" className={this.props.is_md ? "mt-3 bg-gradient-livenyte" : "ml-3 bg-gradient-livenyte" } size={this.props.is_md ? "md" : "md"} onClick={() => this.handleButtonClick("register")}>
                      Sign Up
                  </Button>
                </NavItem>
                }
                {loggedIn &&
                <NavItem>
                    <Link to={{pathname: 
                      userIsSpecialist && !fanMode ? `/dash/celeb-dashboard/` : 
                      userIsBusiness  && !fanMode ? `/dash/business-dashboard/` : 
                      userIsAgency  && !fanMode ? `/dash/agency-dashboard/` : `/dash/user-dashboard/`   }} > 
                      <Media className="align-items-center cursor">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            alt="..."
                            className="avatar-sm"
                            src={info && info.image ? info.image : defaultAvatar }
                            onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                          />
                        </span>
                        <Media className="ml-2 d-lg-block">
                          <span className={"mb-0 text-sm font-weight-bold " + (this.props.is_md ? "text-dark" : headRoomOnTop ? "text-white" : "text-dark")}>
                            {info && info.name ? info.name : "Log In"}
                          </span>
                        </Media>
                      </Media>
                  </Link>
                </NavItem>
                }
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { memberInfo, alert, authentication, screenSizeData, fanModeData } = state;
  const { loggingIn, loggedIn,  } = authentication
  const { info, } = memberInfo;
  const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;
  const { fanMode } = fanModeData;


  return {
      loggingIn,
      loggedIn,
      info, 
      alert,
      is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest,
      fanMode

  };
}

const connectedIndexNavbar = connect(mapStateToProps)(IndexNavbar);
export { connectedIndexNavbar as IndexNavbar }; 
